@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

html {
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
}

a,
a:hover,
a:focus {
    outline: none;
    text-decoration: none;
}

ul,
li {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

input,
textarea,
button {
    padding: 0;
    font-family: 'Open Sans', sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-ms-clear {
    display: none;
}

body {
    margin: 0;
    padding: 0;
}
